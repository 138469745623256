<template>
<div id="app-container" :class="getMenuType">
    <top-nav />
    <sidebar />
    <main>
        <div class="container-fluid">
            <router-view />
        </div>
    </main>
    <footer-component/>
     <div id="spinnerLoading" ref="loader" v-bind:class="{active: activeLoader ? true : false}">
        <b-spinner label="Loading..." variant="primary"></b-spinner>
        <span>{{textOnLoader}}</span>
    </div>
</div>
</template>

<script>
import Sidebar from '../../containers/Sidebar'
import TopNav from '../../containers/TopNav'
import Footer from '../../containers/Footer'
import {
    mapGetters
} from 'vuex'

export default {
    components: {
        'top-nav': TopNav,
        'sidebar': Sidebar,
        'footer-component': Footer
    },
    data() {
         return {
            show: false,
            activeLoader: false,
            textOnLoader: ""
        }
    },
    computed: {
        ...mapGetters(['getMenuType'])
    },
    methods: {
        toggleLoader(text = ""){
          this.activeLoader = !this.activeLoader;
          this.textOnLoader = text;
        },

        showLoader(text = ""){
            this.activeLoader = true;
            this.textOnLoader = text;
        },

        closeLoader(text = ""){
            this.activeLoader = false;
            this.textOnLoader = "";
        },

        textLoader(text){
            this.textOnLoader = text;
        }
    }
}
</script>
<style type="text/css">
  #spinnerLoading{
    background-color: rgba(255,255,255,0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 9999;
    display: none;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  #spinnerLoading.active{
    display: flex;
  }

  .toasted.toasted-primary.notificacionesToast{
    max-width: 350px;
    color: white;
    cursor: pointer;
    padding: 1em 1.5em;
  }
</style>
